import React from "react";
import { Card } from "antd";
import { ComposedChart, BarChart, Bar, Line, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, Cell } from "recharts";
import moment from "moment";
import theme from "../themes/theme";
import animateGlobalState from '../contexts/Animate';
import themeModeGlobalState from "../contexts/ThemeMode";
import * as config from "../config";

const colors = [
    theme.colors.orange.background,
    theme.colors.green.background,
    theme.colors.purple.background,
    theme.colors.yellow.background,
    theme.colors.teal.background,
];

const CustomComposedChart = (props) => {
    const {
        key,
        title,
        width,
        height,
        data,
        chartType,
        chartStructure,
        timeType,
        XAxisKey,
        YAxisUnit,
        secondYAxis,
        secondYAxisUnit,
        replaceMonthToQuarter,
        barColorByValue,
        tooltipOutsideBoxPosition
    } = props;

    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();

    const XAXIS_KEY = XAxisKey || "date";

    const timeFormatter = (value: string) => {
        if (timeType === "day") {
            return moment(value).format(config.DATE_TIME_FORMAT.DATE);
        }
        if (timeType === "month") {
            if (replaceMonthToQuarter) {
                return `T${moment(value).endOf('quarter').format(config.DATE_TIME_FORMAT.MONTH)}`;
            }
            return `T${moment(value).format(config.DATE_TIME_FORMAT.MONTH)}`;
        }
        if (timeType === "quarter") {
            return `Q${moment(value).format(config.DATE_TIME_FORMAT.QUARTER)}`;
        }
        if (timeType === "year") {
            return moment(value).format(config.DATE_TIME_FORMAT.YEAR);
        }
        return value;
    }

    const tickFormatter = (value: number, unit: string, axis?: boolean) => {
        if (unit === "%") {
            return `${Number(value*100).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })}${axis ? "%" : ""}`;
        }

        if (YAxisUnit === 'billion') {
            return Number(value / (1000 * 1000 * 1000)).toLocaleString(config.LOCALE_NUMBER_FORMAT);
        }

        return Number(value).toLocaleString(config.LOCALE_NUMBER_FORMAT);
    };

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip">
                    <div className="text-black"><strong>{timeFormatter(label)}</strong></div>
                    <div style={{color: payload[0]?.value < 0 ? theme.colors.red.text[themeMode] : theme.colors.green.text[themeMode]}}>
                        <strong>{payload[0].name}: {tickFormatter(payload[0].value, payload[0].unit)}</strong>
                    </div>
                    {payload.slice(1).map((entry) => {
                        return (
                            <div key={`tooltip-${entry.name}`} style={{color: entry.color}}>
                                <strong>{entry.name}: {tickFormatter(entry.value, entry.unit)}</strong>
                            </div>
                        )
                    })}
                </div>
            )
        }
        return null;
    }

    return (
        <Card bodyStyle={{ padding: 8, backgroundColor: theme.colors.chartBackground[themeMode] }}>
            <div className="text-one-line uppercase font-bold mb-2" title={title}>{title}</div>

            {!data.length &&
            <div className="absolute inset-center text-lg">Không có giao dịch</div>
            }

            {chartType === "composed" &&
            <ComposedChart
                width={width}
                height={height}
                data={data}
                margin={{top: 5, right: secondYAxis ? 0 : 20, left: 5, bottom: 0}}
            >
                <defs>
                    <linearGradient id={`${key}_${width}_${height}`} x1="0" y1="0" x2="1" y2="1">
                        <stop
                            offset={0.5}
                            stopColor={theme.colors.green.background}
                        />
                        <stop
                            offset={0.5}
                            stopColor={theme.colors.red.background}
                        />
                    </linearGradient>
                </defs>

                <XAxis
                    dataKey={XAXIS_KEY}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => timeFormatter(tickValue)}
                />
                <YAxis
                    yAxisId="left"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, YAxisUnit, true)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    domain={["auto", "auto"]}
                />
                {secondYAxis &&
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, secondYAxisUnit, true)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                />
                }
                <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.gray.background}
                    strokeWidth={0.2}
                />

                {tooltipOutsideBoxPosition ? 
                    (
                    <Tooltip
                        cursor={false}
                        itemStyle={{padding: 0}}
                        labelStyle={{ color: 'black', fontWeight: 'bold' }}
                        labelFormatter={(value) => timeFormatter(value)}
                        wrapperStyle={{fontWeight: barColorByValue ? '': 'bold', zIndex: 1}}
                        formatter={(value, name, props) => tickFormatter(value, props.unit)}
                        position={{y: tooltipOutsideBoxPosition==='bottom' ? height : -150}}
                        content={barColorByValue ? data => renderTooltip(data) : null}
                    />
                    )
                : barColorByValue ? (
                    <Tooltip
                        cursor={false}
                        content={data => renderTooltip(data)}
                    />
                ) : (
                    <Tooltip
                        cursor={false}
                        itemStyle={{padding: 0}}
                        labelStyle={{ color: 'black', fontWeight: 'bold' }}
                        labelFormatter={(value) => timeFormatter(value)}
                        wrapperStyle={{fontWeight: 'bold'}}
                        formatter={(value, name, props) => tickFormatter(value, props.unit)}
                    />
                )}

                {(chartStructure.bar.length > 0 || chartStructure.line.length > 0 || chartStructure.area.length || secondYAxis) &&
                    <Legend wrapperStyle={{fontSize: theme.fontSizes.tiny}} />
                }

                {chartStructure.bar && chartStructure.bar.map((item, index) => {
                    if (barColorByValue) {
                        return <Bar
                            connectNulls
                            isAnimationActive={animate}
                            yAxisId={item.yAxisType}
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            name={item.name}
                            unit={item.unit}
                            fill={`url(#${key}_${width}_${height})`}
                            radius={theme.chart.bar.radius.vertical}
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry[item.dataKey] > 0 ? theme.colors.green.background : theme.colors.red.background}
                                />
                            ))}
                        </Bar>
                    }
                    return (
                        <Bar
                            connectNulls
                            isAnimationActive={animate}
                            yAxisId={item.yAxisType}
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            name={item.name}
                            unit={item.unit}
                            // barSize={12}
                            fill={colors[index]}
                            radius={theme.chart.bar.radius.vertical}
                        />
                    )
                })}

                {chartStructure.line && chartStructure.line.map((item, index) => {
                    const colorPlus = barColorByValue ? chartStructure.bar.length + 1 : chartStructure.bar.length;
                    return (
                        <Line
                            connectNulls
                            isAnimationActive={animate}
                            type="monotone"
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            yAxisId={item.yAxisType}
                            name={item.name}
                            unit={item.unit}
                            stroke={colors[index+(chartStructure.bar.length ? colorPlus : 0)]}
                            strokeWidth={2}
                        />
                    )
                })}

                {chartStructure.area && chartStructure.area.map((item, index) => {
                    return (
                        <Area
                            connectNulls
                            isAnimationActive={animate}
                            type="monotone"
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            yAxisId={item.yAxisType}
                            name={item.name}
                            unit={item.unit}
                            stroke={colors[index]}
                            fill={colors[index]}
                            fillOpacity={0.6}
                        />
                    )
                })}
            </ComposedChart>
            }

            {chartType === "stackBar" &&
            <BarChart
                width={width}
                height={height}
                data={data}
                margin={{top: 5, right: 20, left: 5, bottom: 0}}
                stackOffset="sign"
            >
                <XAxis
                    dataKey="date"
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => timeFormatter(tickValue)}
                />
                <YAxis
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, YAxisUnit)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                />
                <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.gray.background}
                    strokeWidth={0.2}
                />
                <Tooltip
                    cursor={false}
                    itemStyle={{padding: 0}}
                    labelStyle={{ color: 'black', fontWeight: 'bold' }}
                    labelFormatter={(value) => timeFormatter(value)}
                    wrapperStyle={{fontWeight: 'bold'}}
                    formatter={(value) => tickFormatter(value, YAxisUnit)}
                />
                <Legend wrapperStyle={{fontSize: theme.fontSizes.tiny}}/>

                {chartStructure?.bar.map((item, index) => {
                    return (
                        <Bar
                            connectNulls
                            isAnimationActive={animate}
                            stackId={"stackBar"}
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            name={item.name}
                            unit={item.unit}
                            fill={colors[index]}
                        />
                    )
                })}
            </BarChart>
            }

            {chartType === "pie" &&
            <PieChart
                width={width}
                height={height}
            >
                <Pie
                    isAnimationActive={animate}
                    data={data}
                    innerRadius={width/7*(3/4)}
                    outerRadius={width/7}
                    cornerRadius={4}
                    paddingAngle={4}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="middle"
                    wrapperStyle={{fontSize: theme.fontSizes.tiny}}
                    formatter={(value, entry) => `${value} (${tickFormatter(entry.payload.percent, YAxisUnit, true)})`}
                />
                <Tooltip
                    contentStyle={{ fontWeight: 'bold' }}
                    formatter={(value) => tickFormatter(value, YAxisUnit, true)}
                />
            </PieChart>
            }
        </Card>
    )
};

export default CustomComposedChart;
